<template>
  <v-card class="box-shadow pa-7 mx-auto" width="100%" max-width="520" outlined>
    <preloader v-if="getting" message="Please wait..." />

    <v-alert v-else-if="!getting && !form" type="info" border="left" text>
      The form doesn't exist.
    </v-alert>
    
    <v-alert 
      v-else-if="!getting && form && !form.published" 
      border="left" 
      class="mb-0"
      type="info" 
      text
    >
      The form was not published yet.
    </v-alert>

    <template v-else>
      <v-card-title class="font-weight-bold text-h6 mb-6 pa-0 secondary--text">
        {{ form.name }}
      </v-card-title>

      <div v-if="fields.length">
        <Form ref="form" :fields="orderBy(fields, 'order', 'asc')" :form="form" />
      </div>

      <v-btn 
        @click="validateForm()"
        :loading="status.creating"
        color="accent gradient" 
        block
      >Continue To Checkout</v-btn>
    </template>
  </v-card>
</template>

<script>
import Form from './Form'
import db from '@/firebase/init'
import { orderBy } from 'lodash'
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      orderBy,
      form: null,
      fields: [],
      term: null,
      getting: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.orders.status
    }),
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Form,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('orders', [
      'createOrder'
    ]),

    validateForm() {
      if (this.$refs.form.$refs.form.validate()) {
        this.createOrder({ fields: this.fields, form: this.form })
      }
    },

    getForm() {
      this.getting = true

      let id = this.$route.query.id

      if (id) {
        db.collection('order_forms')
        .doc(id).get()
        .then((doc) => {
          if (doc.exists) {
            this.form = doc.data()
            this.form.id = doc.id
            this.form.ref = doc.ref
            this.getting = false
            this.getFields(this.form)

            if (this.form.terms && this.form.terms.length) {
              this.form.terms.forEach(term => {
                this.$store.dispatch('order_term/getTerm', term)
              })
            }
          }
          else this.getting = false
        })
        .catch(error => {
          console.log(error.message)
          this.getting = false
        })
      }
      else this.getting = false
    },

    getFields(form) {
      this.fields = []

      form.ref
      .collection('fields')
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let field = doc.data()
            field.id = doc.id
            field.ref = doc.ref
            this.fields.push(field)
          })
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    },
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getForm()
    this.$store.dispatch('products/getPrices')
  }
}
</script>